<template>
  <v-data-table
    :headers="headers"
    :items="ArrayEstados"
    :search="search"
    sort-by="calories"
    class="elevation-1 mx-1 mt-5"
  >
    <template v-slot:top>
      <v-toolbar flat color="opcion1">
        <v-toolbar-title>Estados</v-toolbar-title>
        <v-divider  class="mx-4" inset vertical></v-divider>

         <v-flex  xs4 sm4 md3 lg3>
          <v-text-field  justify-center  v-model="search"  append-icon="search" 
          label="Busqueda" single-line hide-details></v-text-field> 
        </v-flex>


        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mx-1" v-bind="attrs" v-on="on">
              <v-icon>add</v-icon>
            </v-btn>

             <v-btn color="success" @click="listar();">
              <v-icon>restart_alt</v-icon>
              </v-btn>

          </template>
          <v-card>



             <v-toolbar color="red">
             <v-toolbar-title class="white--text">{{ formTitle }}</v-toolbar-title>
             <v-spacer></v-spacer>
             <v-icon dark @click="dialog=false">close</v-icon>
             </v-toolbar>




            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="8">
                    <v-text-field v-model="editedItem.nombre_estado" prepend-icon="garage" label="Nombre Estado"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      v-model="editedItem.descripcion"
                      label="Descricion"
                      prepend-icon="description"
                    ></v-text-field>
                  </v-col>

                     <v-col cols="12" sm="6" md="4">

                    <input type="radio" :value="1" v-model="editedItem.activo"> Activo
                    <input type="radio" :value="0" v-model="editedItem.activo"> Inactivo
 
                  </v-col>

                 
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn color="success" @click="save">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">deseas deshabilitar estado?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> edit</v-icon>
      <!--v-icon small @click="deleteItem(item)">delete</v-icon-->
    </template>
    
    <template v-slot:no-data>
      <v-btn color="primary" @click="listar()">
        Reset
      </v-btn>
    </template>


     <template v-slot:[`item.activo`]="{ item }">
        <v-icon>
           {{ item.activo==1 ? "done" : "label_off" }}
        </v-icon>
  </template>



     <template v-slot:[`item.creado`]="{ item }">
        <span>{{ getDate(item.creado) }}</span>
  </template>

  </v-data-table>
</template>

<script>


import axios from 'axios';
import { mapMutations,mapState } from "vuex";

  export default {
    data: () => ({
      search:'',
      dialog: false,
      dialogDelete: false,
      ArrayEstados:[],
      headers: [
        { text: 'Nombre estado', value: 'nombre_estado' },
        { text: 'Descripcion', value: 'descripcion' },
        { text: 'Activo', value: 'activo' },
        { text: 'Creado', value: 'creado' },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
  
      editedIndex: -1,
      editedItem: {
        nombre_estado: '',
        descripcion: '',
        activo: 1,
        creado: new Date()
     
      },
      defaultItem: {
        nombre_estado: '',
        descripcion: '',
        activo: 1,
        creado: new Date()
      },
    }),

    computed: {

        ...mapState(['usuario', 'token']),

      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo estado' : 'Edicion estado'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
     
        
      this.listar();
    },

    methods: {

         ...mapMutations(['mostrarLoading','ocultarLoading']),

      listar(){

            let config={headers:{token:this.token}};
           this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;
          axios.get('estados',
          config
          ).then(function(response){
             console.log(response);
             me.ArrayEstados=response.data;
             me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });


      },
    
    

      editItem (item) {
        this.editedIndex = this.ArrayEstados.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ArrayEstados.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {


        if((this.editedItem.nombre_estado!=='Desconocido')||(this.editedItem.nombre_estado!=='Listo')){


       
       // this.ArrayEstados.splice(this.editedIndex, 1)

                    let config={headers:{token:this.token}};
            
                    axios.put(`estado/${this.editedItem._id}`,
                              { '_id':this.editedItem._id,'activo':0},
                           config
                        ).then(response=>{
                      
                        console.log(response);
                     

                        this.closeDelete();
                        this.listar();

                  }).catch(e=>{
                        console.log(e)
                  });
          }else{
            alert('no puedes deshabilitar estado creado  por defecto');
          }

      
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {


          // Object.assign(this.ArrayEstados[this.editedIndex], this.editedItem)
          // edicion
     if((this.editedItem.nombre_estado!=='Desconocido')||(this.editedItem.nombre_estado!=='Listo')){

            //actualizar datos de item
                     let config={headers:{token:this.token}};
                     this.mostrarLoading({titulo:'Accediendo a datos'});
             
                    let id=this.editedItem._id;
                    let me=this;

                    axios.put(`estado/${id}`,
                      { 
                      '_id':this.editedItem._id,
                      'nombre_estado':this.editedItem.nombre_estado,
                      'descripcion':this.editedItem.descripcion,
                      'activo':this.editedItem.activo
                       },
                       config

                        ).then(response=>{
                      
                        console.log(response);
                          me.ocultarLoading();
                     
                        this.listar();
                        this.close();

                  }).catch(e=>{
                        console.log(e)
                        this.mensaje=e
                  });

     }else{
       alert('no puedes editar estado por defecto');
     }






        } else {
         // this.ArrayEstados.push(this.editedItem)
          //guardado

                //nuevo ejecutivo
                let config={headers:{token:this.token}};
                let me=this;
                this.mostrarLoading({titulo:'Accediendo a datos'});
                axios.post('nuevo-estado',
                          { 
                            'nombre_estado':this.editedItem.nombre_estado,
                            'descripcion':this.editedItem.descripcion,
                            'activo':this.editedItem.activo
                            },
                       config
                ).then(response=>{
                
                  this.close();
                  console.log(response);
                    me.ocultarLoading();
                  this.listar();

                }).catch(e=>{
                      console.log(e)
                    
                });



        }
       
      },


      getDate(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day,
      hour = date.getHours().toString(),
      formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
      minute = date.getMinutes().toString(),
      formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
      second = date.getSeconds().toString(),
      formatedSecond = (second.length === 1) ? ("0" + second) : second;
    return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
  },


    },
  }
</script>